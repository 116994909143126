<script>
import {Searcher} from './js/Searcher';

function copy() {
  Searcher.getInstance().copyText();
}
</script>

<div id="share">
    <div class="dialog">
        <div id="share-hint">
            请复制链接并分享~
        </div>
        <input id="generated-url" type="text" readonly/>
        <a id="copy" on:click={copy}>复制</a>
    </div>
</div>

<style>
    #share {
        position: absolute;
        width: 100%;
        height: 100%;
        display: none;
    }

    #share .dialog {
        padding: 30px;
        height: 150px;
        margin: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        border: 1px solid lightgrey;
        text-align: center;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
    }

    #generated-url {
        border: 1px solid lightgrey;
        width: 300px;
        height: 20px;
        padding: 0 4px;
        margin: 20px 0;
    }

    #share-hint {
        color: grey;
    }

    #copy {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 32px;
        width: 80px;
        border-radius: 8px;
        border: 1px solid lightgrey;
    }
</style>
