<div id="m">
    <Share />
    <Logo/>

    <Nav/>
    <Form />
    <div id="cursor"></div>
</div>

<style>
    input {
        border: 0;
        padding: 0;
    }

    * {
        font-family: 'Helvetica', 'Arial';
    }

    #cursor {
        position: absolute;
        left: 0;
        top: 0;
        width: 17px;
        height: 23px;
        background: none;
    }
</style>

<script>
    import Logo from './Logo.html';
    import Nav from './Nav.html';
    import Form from './Form.html';
    import Share from './Share.html';
    import {initBDTJ, initGA} from './js/analyser';
    import {Searcher} from './js/Searcher';

    import { onMount } from 'svelte';
    onMount(()=>{
      initBDTJ();
      initGA();
      Searcher.getInstance().play();
    });
</script>
