<span class="s_ipt_wr {{focusClassName}} {{hoverClassName}}"
      on:mouseenter={mouseenter}
      on:mouseout={mouseout}>
    <input id="kw" name="wd" class="s_ipt" maxlength="255" autocomplete="off"
           bind:value={text}
           on:change={change}
           on:focus={focus}
           on:blur={blur}>
</span>

<style>
    .s_ipt {
        width: 526px;
        height: 22px;
        font: 16px/18px arial;
        line-height: 22px \9;
        margin: 6px 0 0 7px;
        padding: 0;
        background: transparent;
        border: 0;
        outline: 0;
        -webkit-appearance: none;
    }

    .s_ipt_wr {
        border: 1px solid #b6b6b6;
        border-color: #7b7b7b #b6b6b6 #b6b6b6 #7b7b7b;
        background: #fff;
        display: inline-block;
        vertical-align: top;
        width: 539px;
        height: 34px;
        margin-right: 0;
        border-right-width: 0;
        border-color: #b8b8b8 transparent #ccc #b8b8b8;
        overflow: hidden;
    }

    .s_ipt_wr:hover, .s_ipt_wr.ipthover {
        border-color: #999 transparent #b3b3b3 #999;
    }

    .s_ipt_wr.iptfocus {
        border-color: #4791ff transparent #4791ff #4791ff;
    }
</style>

<script>
    import {Searcher} from './js/Searcher';

    let text = '';
    let focusClassName = '';
    let hoverClassName = '';

    function change() {
      Searcher.getInstance().text = text
    }
    function focus() {
      focusClassName = 'iptfocus';
    }
    function blur() {
      focusClassName = '';
    }

    function mouseenter() {
      hoverClassName = 'ipthover';
    }
    function mouseout() {
      hoverClassName = '';
    }
</script>
